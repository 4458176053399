.show-if-big {
  display: none;
}

@media (min-width: 768px) {
  ion-footer.hidden {
    display: none;
  }

  .show-if-big {
    display: block;
  }
}
