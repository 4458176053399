.elevated-search-box {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.elevated-search-container ion-input {
  text-align: left;
}

.elevated-search-container ion-icon {
  margin: 0 0.5rem;
  width: 28px;
  height: 28px;
}

ion-button.search-input-icon {
  --background: var(--ion-color-primary);
  cursor: pointer;
  --border-radius: 0 0em 0em 0;
  --padding-top: 0;
  --padding-bottom: 0;
  margin: 0;
  height: auto;
}

.search-input-icon ion-icon {
  width: 25px;
  height: 25px;
}

.elevated-search-container {
  width: 100%;
  font-size: 1rem;
  background: var(--ion-color-light) 0% 0% no-repeat padding-box;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--ion-color-primary);
}

.elevated-search-container:focus-within {
  /* border: 3px solid var(--ion-color-primary); */
}
