.elevated-select-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 5rem;
}

ion-content ion-popover {
  --background: var(--ion-color-light);
}

ion-app ion-alert.multiple-select-alert {
  --background: var(--ion-color-light);
}

ion-app ion-alert.select-alert {
  --background: var(--ion-color-light);
}

ion-app ion-alert.sc-ion-alert-md-h,
.ion-app ion-alert.sc-ion-alert-md-s {
  --background: var(--ion-color-light);
}

.elevated-select-button {
  position: absolute;
  bottom: 4em;
  right: 3em;
}

ion-content .elevated-select-button ion-icon {
  width: 2em;
  height: 2em;
}

.select-label {
  display: flex;
  align-items: flex-end;
  padding-left: 0.5rem;
}
