.elevated-map-container {
  height: 100%;
  width: 100%;
}
.leaflet-container {
  height: 100% !important;
  width: 100% !important;
}

.map-small-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.map-screenshot-container {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.map-screenshot {
  cursor: pointer;
  width: 30vw;
  background-color: var(--ion-color-primary);
}

.leaflet-control-locate a {
  font-size: 1.4em;
  color: #444;
  cursor: pointer;
}

.leaflet-control-locate a span {
  line-height: 30px;
}

.leaflet-control-locate.active a {
  color: #2074b6 !important;
}

.leaflet-control-locate.active.following a {
  color: #fc8428 !important;
}
