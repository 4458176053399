.date-picker-container {
  display: flex;
  flex-direction: column;
}

.date-picker > * {
  margin: 1rem 0;
}

.DateInput_input {
  color: var(--ion-color-primary) !important;
  background: var(--ion-color-light);
}

.SingleDatePickerInput__withBorder {
  border: none;
  background: var(--ion-color-light);
}

.DateInput_input::placeholder {
  color: var(--ion-color-secondary) !important;
}

.date-picker-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
}
