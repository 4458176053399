/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #0091ff;
  --ion-color-primary-rgb: 0, 145, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0080e0;
  --ion-color-primary-tint: #1a9cff;

  --ion-color-secondary: #449fea;
  --ion-color-secondary-rgb: 68, 159, 234;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #3c8cce;
  --ion-color-secondary-tint: #57a9ec;

  --ion-color-tertiary: #f5f6fa;
  --ion-color-tertiary-rgb: 245, 246, 250;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #d8d8dc;
  --ion-color-tertiary-tint: #f6f7fb;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #e3b007;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #6a6a6d;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f5f6fa;
  --ion-color-light-rgb: 245, 246, 250;
  --ion-color-light-contrast: var(--ion-color-secondary);
  --ion-color-light-contrast-rgb: var(--ion-color-secondary-rgb);
  --ion-color-light-shade: #d8d8dc;
  --ion-color-light-tint: #f6f7fb;
  --ion-font-family: Sarabun;
}

.ios body,
.md body {
  --ion-background-color: var(--ion-color-light);
}

html {
  font-size: 14px;
}

.grecaptcha-badge {
  visibility: hidden;
}
