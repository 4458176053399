.listing-category-header {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--ion-color-primary);
  text-decoration: underline;
}

.listing-category-footer {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem 2rem;
  border-bottom: 1px solid;
}

.listing-product-box {
  height: 154px;
}

.listing-product {
  position: relative;
  height: 140px;
  background: var(--ion-color-light) 0% 0% no-repeat padding-box;
  box-shadow: 7px 7px 7px var(--ion-color-light-shade);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.listing-product .listing-product-outos {
  text-align: center;
  width: 100%;
  font-size: 0.7rem;
  border-radius: 10px;
  padding: 0 0.5rem;
  background: var(--ion-color-danger);
  color: white;
}

.price-tag {
  font-weight: bold;
  font-size: 1.2rem;
  color: var(--ion-color-medium);
}

.listing-detail-1 {
  width: 100%;
}

.listing-detail-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: center;
  color: var(--ion-color-primary);
}

.product-name {
  font-size: 1.2rem;
  color: var(--ion-color-primary);
  font-weight: bold;
}

.product-uom {
  font-size: 0.8rem;
  color: var(--ion-color-medium);
}

.qty-message {
  font-size: 0.7rem;
  text-align: center;
  color: var(--ion-color-secondary);
}

.qty-error {
  text-align: center;
  font-size: 0.7rem;
  color: var(--ion-color-danger);
  margin: 0.2rem;
}

.listing-detail-2 {
  font-size: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.listing-detail-3 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.listing-product .listing-image,
.listing-product .listing-image img {
  width: 130px;
  height: 140px;
  color: var(--ion-color-primary);
  object-fit: cover;
}

.listing-product .listing-image-container {
  margin: 0 auto;
  width: 130px;
  height: 140px;
}

.listing-qty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.listing-qty ion-input.listing-input {
  /* border: 1px solid var(--ion-color-secondary); */
  /* height: 2.5rem; */
  color: var(--ion-color-medium);
  background: var(--ion-color-light);
  font-weight: bold;
}

.listing-detail-options ion-label {
  font-size: 0.6rem;
  color: var(--ion-color-medium);
}

.listing-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* TODO */
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  left: 120px;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
  padding: 1rem;
  background: white;
  overflow: hidden;
}

.listing-qty ion-input {
  width: 40px;
  text-align: right;
  --padding-end: 5px;
}

ion-content .listing-product ion-button.listing-button-add {
  font-size: 0.9rem;
}

ion-content ion-button.listing-button-qty {
  margin: 0;
  /* height: 2.5rem;
  width: 2.5rem; */
}

ion-content .listing-product ion-button.listing-button-add ion-label {
  margin: 0.4rem;
  text-transform: initial;
}

.company-banner {
  width: 100%;
  display: flex;
  height: 150px;
  justify-content: center;
  object-fit: cover;
}

.company-banner img {
  height: 150px;
  max-width: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .listing-detail-1 {
    height: initial;
  }
  .listing-product.as-box .listing-image,
  .listing-product.as-box .listing-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 23px;
  }

  .listing-product.as-box .listing-image-container {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 23px;
  }

  .listing-product-item.as-box .listing-product-box {
    height: 320px;
    margin: 1.5rem 1rem;
  }

  .listing-product.as-box {
    position: relative;
    border-radius: 23px;
    height: 300px;
    width: 300px;
    background: var(--ion-color-light) 0% 0% no-repeat padding-box;
    box-shadow: 7px 7px 7px var(--ion-color-light-shade);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .listing-multi-product-container {
    display: flex;
    justify-content: flex-start;
  }

  .listing-product.as-box .listing-detail {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #e5e5e500 40%, #c1bbbb00 57%, #453f3fc3 100%) 0% 0%
      no-repeat padding-box;
    color: white;
    justify-content: flex-end;
    top: 0;
    left: 0px;
    border-radius: 23px;
    padding: 1rem;
  }

  .listing-product.as-box .listing-detail .listing-detail-price {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  .listing-product.as-box .listing-detail .listing-detail-price .product-uom {
    color: var(--ion-color-light) !important;
    font-size: 0.7rem;
    margin-left: 1rem;
  }
  .listing-product.as-box .listing-detail .price-tag {
    background: var(--ion-color-primary);
    border-radius: 11px;
    color: white;
    font-weight: bold;
    padding: 0 0.5rem;
    font-size: 1rem;
  }

  .listing-product.as-box .listing-detail-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .listing-product.as-box .listing-detail .listing-detail-price {
    margin-bottom: 1rem;
  }
  .listing-product.as-box .listing-detail .product-name {
    color: white;
    font-weight: bold;
  }

  .company-banner {
    width: 100%;
    display: flex;
    justify-content: center;
    object-fit: cover;
    height: 300px;
  }

  .company-banner img {
    height: 300px;
    max-width: 100%;
    object-fit: cover;
  }
}

ion-footer.total-footer {
  display: flex;
  justify-content: space-between;
}
