@font-face {
  font-family: Sarabun;
  src: url('../../../assets/fonts/Sarabun/Sarabun-Regular.ttf');
}

@font-face {
  font-family: Sarabun;
  src: url('../../../assets/fonts/Sarabun/Sarabun-Bold.ttf');
  font-weight: bold;
}

ion-spinner {
  --spinner-color: var(--ion-color-primary);
  color: var(--ion-color-secondary);
  font-weight: bold;
}

/* ion-content {
  --background: var(--ion-color-light);
} */

ion-content ion-button.button-solid,
ion-footer ion-button.button-solid,
ion-button.button-solid {
  --background: transparent linear-gradient(180deg, var(--ion-color-secondary) 0%, var(--ion-color-primary) 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0.4em 0.4em 0.8em var(--ion-color-light-shade);
  --border-radius: 4px;
  --color: var(--ion-color-light);
}

ion-content ion-button.button-full,
ion-button.button-full {
  width: 100%;
}

ion-modal ion-footer ion-button {
  font-weight: bold;
  --color: var(--ion-color-light);
  letter-spacing: 0;
}

ion-content ion-button.transparent,
ion-footer ion-button.transparent,
ion-button.transparent {
  --background: transparent !important;
  background: transparent !important;
  --color: var(--ion-color-secondary);
  border-radius: 0.2em;
  --border-radius: 0.2em;
  font-weight: bold;
  letter-spacing: 0;
  font-size: 1rem;
  height: 3.5rem;
}

@media (min-width: 992px) {
  ion-tab-bar {
    display: none;
  }

  .ion-page {
    /* padding: 0 10%; */
    background: white;
  }

  ion-modal .ion-page {
    padding: 0;
  }

  ion-footer ion-toolbar.responsive {
    padding-bottom: 100px;
  }

  ion-modal ion-footer ion-toolbar.responsive {
    padding-bottom: 0;
  }

  html {
    font-size: 17px !important;
  }
}

.store-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-medium);
  margin-bottom: 30px;
}

.store-title img {
  height: 50px;
  margin-right: 1rem;
  object-fit: cover;
}

.shadow-container {
  height: 100%;
  /* box-shadow: 0.4rem 0.4rem 0.8rem var(--ion-color-light-shade); */
}

#background-content {
  background: white !important;
}

.no-big-screen {
  display: block !important;
}

.big-screen {
  display: none !important;
}

.option-title {
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
  padding: 2rem;
}

@media (min-width: 768px) {
  .big-screen {
    display: block !important;
  }
  .no-big-screen {
    display: none !important;
  }
  .options-list {
    margin: 0 10%;
  }
  .body-container {
    --background: white;
  }
  .shadow-container {
    height: 100%;
    padding-right: 10%;
    padding-left: 10%;
    background: white;
    /* box-shadow: 0.4rem 0.4rem 0.8rem var(--ion-color-light-shade); */
  }

  .store-title img {
    height: 80px;
  }

  .store-title {
    font-size: 2rem !important;
    justify-content: flex-start;
  }

  /* ion-content ion-button.button-solid.button-full,
  ion-footer ion-button.button-solid.button-full,
  ion-button.button-solid.button-full {
    font-size: 1rem;
    height: 50px;
  } */
}

.app-grid {
  outline: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

.SingleDatePickerInput {
  background: var(--ion-color-light);
}

.ion-display-flex {
  display: flex !important;
}

.notification-message {
  --color: var(--ion-color-light) !important;
  color: var(--ion-color-light);
}
