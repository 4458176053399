.textarea-container {
}

.textarea-container ion-label {
  color: var(--ion-color-primary);
  padding: 0;
}

.textarea-container ion-textarea {
  padding-top: 0;
  --padding-top: 0;
  color: var(--ion-color-primary);
}
