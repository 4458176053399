.product-detail-container {
}

.product-detail-resume {
  margin: 10px 10%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.product-detail-resume-data {
  display: flex;
  flex-direction: column;
}

.product-detail-image {
  object-fit: contain;
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.product-detail-image img {
  object-fit: contain;
  max-width: 100%;
}

.product-detail-description {
  box-shadow: 0.4rem 0.4rem 0.8rem var(--ion-color-light-shade);
  margin: 0 10%;
  min-height: 200px;
  padding: 20px;
  color: var(--ion-color-medium);
  font-size: 1.2rem !important;
}

.product-detail-pricing {
  color: var(--ion-color-primary);
  font-size: 1.3rem;
  font-weight: bold;
  width: 50%;
}

.product-detail-purchase {
  display: flex;
  justify-content: center;
  max-width: 150px;
}

@media (min-width: 768px) {
  .product-detail-purchase {
    max-width: 150px;
  }

  /* .product-detail-purchase ion-button {
    --height: 80px;
  } */

  .product-detail-resume {
    margin: 50px 10%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .product-detail-container .price-tag {
    font-size: 1.7rem !important;
  }
  .product-detail-container .product-uom {
    font-size: 1rem !important;
  }
  .product-detail-container .product-name {
    font-size: 2rem !important;
  }

  .product-detail-image {
    object-fit: cover;
    max-height: 350px;
    display: flex;
    justify-content: center;
  }

  .product-detail-image img {
    object-fit: cover;
    max-height: 350px;
  }

  .product-detail-description {
  }
}
