.checkout-footer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.checkout-footer .supplier {
  text-align: center;
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 1.2rem;
}

.checkout-footer .info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.checkout-footer .info .qty-info {
  color: var(--ion-color-primary);
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}

.checkout-footer .info .amount-info {
  color: var(--ion-color-primary);
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  align-items: flex-end;
}

.checkout-footer .checkout-message {
  color: var(--ion-color-warning);
  font-size: 1rem;
  display: flex;
  align-items: flex-end;
}

.final-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.order-ticket {
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: center;
}
