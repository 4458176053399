.elevated-box {
  display: flex;
  position: "relative";
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 0.7em;
  margin-top: 0.7em;
}
.elevated-container {
  width: 100%;
  min-height: 3rem;
  background: var(--ion-color-light) 0% 0% no-repeat padding-box;
  box-shadow: 0.4rem 0.4rem 0.8rem var(--ion-color-light-shade);
  padding: 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.elevated-container:focus-within {
  border: 2px solid var(--ion-color-primary);
}

.elevated-search-box {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 45px;
}

ion-button.search-input-icon {
  --background: var(--ion-color-primary);
  cursor: pointer;
  --border-radius: 0 0em 0em 0;
  --padding-top: 0;
  --padding-bottom: 0;
  margin: 0;
}

.search-input-icon ion-icon {
  width: 25px;
  height: 25px;
}

.elevated-search-container {
  width: 100%;
  font-size: 1rem;
  background: var(--ion-color-light) 0% 0% no-repeat padding-box;
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px solid var(--ion-color-primary);
}

.elevated-search-container:focus-within {
  /* border: 3px solid var(--ion-color-primary); */
}

.elevated-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  height: 100%;
}

.elevated-error {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--ion-color-warning);
  font-size: 0.8rem;
  padding: 0.1rem 0.4rem;
}

.elevated-error ion-label {
  cursor: pointer;
  justify-self: flex-end;
  margin-left: auto;
}

.elevated-item {
  position: relative;
  --background: var(--ion-color-light) 0% 0% no-repeat padding-box;
  --background-activated: var(--ion-color-light) 0% 0% no-repeat padding-box;
  --background-focused: var(--ion-color-light) 0% 0% no-repeat padding-box;
  --border-style: none;
  --padding-start: 0;
  --padding-end: 0;
  --highlight-color-invalid: var(--ion-color-warning);
}

.elevated-item ion-icon {
  margin-right: 1rem;
}

.elevated-input {
  --background: var(--ion-color-light);
  --color: var(--ion-color-secondary);
  font-size: 1.2rem;
}

.elevated-label {
  font-size: 1rem;
}
