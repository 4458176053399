.category-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100px !important;
  height: 80px !important;
  text-align: center;
  background-color: var(--ion-color-light);
  margin-right: 1rem !important;
  background: var(--ion-color-light) 0% 0% no-repeat padding-box;
  box-shadow: 7px 7px 14px var(--ion-color-light-shade);
  padding: 0.2rem 0.3rem;
  cursor: pointer;
  /* text-shadow: 0.5rem 0.5rem 0.5rem var(--ion-color-dark); */
}

.category-box.selected {
  background: transparent linear-gradient(90deg, var(--ion-color-secondary) 0%, var(--ion-color-primary) 100%) 0% 0%
    no-repeat padding-box;
}

.category-box ion-label {
  font-size: 1rem;
  /* border-radius: 50px !important; */
}

ion-toolbar.category-header {
  --border-style: none;
  --background: white;
  --color: var(--ion-color-primary);
  --padding-start: 1rem;
  position: relative;
}

ion-toolbar.category-header.small {
  --height: 20px;
  --min-height: 20px;
}
